import Cookies from 'js-cookie';
import axios from 'axios';


function getAuthToken() {
    var ssoCookie = Cookies.get('ssoid');
    return ssoCookie;
}

async function Authenticated() {
    var ssoCookie = Cookies.get('ssoid');
    if (ssoCookie === null || ssoCookie === undefined) {
        return false;
    } else {
        const headers = {
            headers: {
                "X-Authentication": this.getAuthToken(),
                "Content-Type": "application/json"
            }
        };
        const resp = await axios.get(`${process.env.REACT_APP_API_URL}/userDetails/`, headers);
        console.log('(Auth) resp :: ', resp);

        if (resp.data.error) {
            console.log('auth (userDetails) error', resp.data.error);
            return await false;
        } else {
            return await resp.data.ssoLoginStatus;
        }
    }
}


export default {
    getAuthToken,
    Authenticated
}