import React from 'react';

import './Footer.scss';

// Components

import Regs from "./Regs/Regs";


export default function Footer() {
  return (
    <div className="footer-container">
      <div className="container">
        <div className="row">

          <div className="col">
            <div className="footer-content-container">
              <Regs />
              <div className="footer-social-media">
                <div className="follow-us">Follow us</div>
                <div className="social-media-images">
                  <a rel="noopener noreferrer" target="_blank" href='https://twitter.com/Betfair_Aus'>
                    <div className="link-image-twitter" />
                  </a>
                  <a rel="noopener noreferrer" target="_blank" href='https://www.facebook.com/betfairaustralia'>
                    <div className="link-image-facebook" />
                  </a>
                  <a rel="noopener noreferrer" target="_blank" href='https://www.linkedin.com/company/betfair-australia'>
                    <div className="link-image-youtube" />
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
