import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import './CompetitionSelector.scss';

// Controllers

import apiController from '../../../controllers/ApiController';
import localStorageController from '../../../controllers/LocalStorageController';


class CompetitionSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            compListData: {},
            dataReady: false,
            selectedCompetitionText: ''
        }

        this.changeCompetition = this.changeCompetition.bind(this);
    }

    componentDidMount() {
        this.checkLocalStorageForData();
        //this.getCompetitions();
    }

    checkLocalStorageForData() {
        if (this.props.match.params.compid) {
            if (localStorageController.isAvailable()) {
                const storedCompListData = localStorageController.getData('bft.compListData');
                if (storedCompListData && storedCompListData.dataValidUntil) {
                    const storedCompListDataTimeDiff = moment().diff(storedCompListData.dataValidUntil, 'minutes');
                    if (storedCompListDataTimeDiff > 0) {
                        this.getCompetitions();
                    } else {
                        // using local storage data
                        if (storedCompListData.savedData.length > 1) {
                            const selectedMenuItem = storedCompListData.savedData.filter((x) => { return x.competitionID === Number(this.props.match.params.compid) })[0];
                            if (selectedMenuItem === undefined || !selectedMenuItem) {
                                this.getCompetitions();
                            } else {
                                this.setState({ compListData: storedCompListData.savedData, selectedCompetitionText: selectedMenuItem.description, dataReady: true});
                            }
                        }
                    }
                } else {
                    this.getCompetitions();
                }
            } else {
                this.getCompetitions();
            }
        }
    }

    async getCompetitions() {
        if (this.props.match.params.compid) {
            const res = await apiController.getCompList();
            if (res.status === 200) {
                localStorageController.storeData('bft.compListData', res.data);
                const selectedMenuItem = res.data.filter((x) => { return x.competitionID === Number(this.props.match.params.compid) })[0];
                if (res.data.length > 1) { // If we have only 1 competition, then do not display dropdown. otherwise display if greater than 1
                    if (selectedMenuItem === undefined || !selectedMenuItem) {
                        this.setState({ compListData: res.data, selectedCompetitionText: '-', dataReady: false});
                    } else {
                        this.setState({ compListData: res.data, selectedCompetitionText: selectedMenuItem.description, dataReady: true});
                    }
                    
                }
            }   
        }
    }

    changeCompetition(evt) {
        this.setState({ selectedCompetitionText: evt.currentTarget.innerText });
        this.props.history.push(`/${evt.currentTarget.value}`);
    }

    render() {
        if (this.state.dataReady && this.props.match.params.compid && this.state.compListData && this.state.compListData.length > 0) {
            return (
                <div className="comp-selector-container float-right">
                    <div className="dropdown">
                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="compDropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title={this.state.selectedCompetitionText}>
                            {this.state.selectedCompetitionText}
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="compDropdownMenu">
                            {this.state.compListData.map((compItem, key) => (
                                <button key={key} className={this.state.selectedCompetitionText === compItem.description ? 'dropdown-item hidden' : 'dropdown-item'} type="button" value={compItem.competitionID} onClick={this.changeCompetition} title={compItem.description}>{compItem.description}</button>
                            ))}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="comp-selector-container float-right">&nbsp;</div>
            );
        }
    }
}

export default withRouter(CompetitionSelector);