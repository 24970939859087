import React from 'react';
import { withRouter } from 'react-router-dom';

import './CompetitionNav.scss';


class CompetitionNav extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            prevIdx: null,
            nextIdx: null,
            roundIdx: 0,
            compData: {},
            dataReady: false
        }
    }

    componentDidMount() {
        this.setState({compData: this.props.compData, roundIdx: this.props.roundIdx});
        this.updateRoundNav();
    }

    componentDidUpdate(prevProps) {
        if (this.props.roundIdx !== prevProps.roundIdx) {
            this.setState({compData: this.props.compData, roundIdx: this.props.roundIdx});
            this.updateRoundNav();
        }
    }

    updateRoundNav() {
        let prevIdx = Number(this.props.roundIdx);
        prevIdx--;
        let nextIdx = Number(this.props.roundIdx);
        nextIdx++;
        this.setState({prevIdx: prevIdx--, nextIdx: nextIdx++, dataReady: true});
    }

    render() {
        if (this.state.dataReady) {

            return (
                <div className="comp-navbar content-responsive">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                               <i data-round={this.state.prevIdx} onClick={this.props.changeRound} className={Number(this.state.roundIdx) <= 0 ? 'hiding' : 'icon-chevron-left previous-round'} title="Previous Round"></i>
                               {this.state.compData.rounds[this.state.roundIdx].description}
                               <i data-round={this.state.nextIdx} onClick={this.props.changeRound} className={Number(this.state.roundIdx) === this.state.compData.rounds.length-1 ? 'hiding' : 'icon-chevron-right next-round'} data-toggle="tooltip" title="Next Round"></i>
                            </div>
                        </div>
                    </div>
                </div>
            );
          } else {
            return (
                <div className="comp-navbar content-responsive"></div>
            );
          }
    }
}

export default withRouter(CompetitionNav)
