import React from 'react';
import { withRouter } from "react-router-dom";
import Cookies from 'js-cookie';

import './Header.scss';

// Components

import UserProfile from "./UserProfile/UserProfile";
import CompetitionSelector from "./CompetitionSelector/CompetitionSelector";
import Navigation from "./Navigation/Navigation";


class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: null,
            userData: {},
            dataReady: false
        }
    }

    componentDidMount() {
        this.setState({isAuthenticated: this.props.userData.ssoLoginStatus});
    }

    logout() {
        if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
            Cookies.remove('ssoid', {domain: process.env.REACT_APP_LOGOUT_DOMAIN, path: '/'});
            document.forms.logout.submit();
        }

        if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
            Cookies.remove('ssoid');
            console.log('Logged out');
            window.location = process.env.REACT_APP_PUBLIC_URL;
        }
    }

    render() {
        return (
            <header className="header-container">

                <div className="top-header-bar">
                    <div className="container">
                        <div className="row">

                            <div className="col-6">
                                <a href={process.env.REACT_APP_PUBLIC_URL} className="betfair-logo float-left" title="Betfair | Tipping">&nbsp;</a>
                            </div>

                            <div className="col-6">
                                <div className="login-actions float-right">
                                    <a href={`${process.env.REACT_APP_LOGIN_URL}?product=${process.env.REACT_APP_PRODUCT_KEY}&redirectMethod=GET&url=${process.env.REACT_APP_PUBLIC_URL}${this.props.match.url}`} className={this.state.isAuthenticated ? 'login-link hidden' : 'login-link '} title="Login">Login</a>

                                    <form id="logout" action={process.env.REACT_APP_LOGOUT_URL} className={this.state.isAuthenticated ? 'logout-link' : 'logout-link hidden'}>
                                        <input type="hidden" name="product" value="betfair-tipping" />
                                        <input type="hidden" name="redirectMethod" value="POST" />
                                        <input type="hidden" name="url" value={process.env.REACT_APP_LOGOUT_REDIRECT_URL} />
                                        <div onClick={this.logout} className="logout-button" title="Logout">
                                            <span className="header-button-text">Logout</span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="hr-line"></div>

                <div className="profile-header-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-8">
                                <UserProfile userData={this.props.userData} />
                            </div>
                            <div className="col-4">
                                <CompetitionSelector compListData={this.props.compListData} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="navigation-header-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <Navigation {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>

            </header>
        )
    }
}

export default withRouter(Header)