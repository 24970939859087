import React from 'react';
import { withRouter } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import PropagateLoader from 'react-spinners/PropagateLoader';
import moment from 'moment';

import './AvailableCompetitions.scss';

// Controllers

import apiController from '../../controllers/ApiController';
import localStorageController from '../../controllers/LocalStorageController';

// Components

import Header from '../Header/Header';
import Footer from '../Footer/Footer';


class AvailableCompetitions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userData: {},
            compListData: {},
            dataReady: false
        }

        this.changeCompetition = this.changeCompetition.bind(this);
    }

    componentDidMount() {
        this.checkLocalStorageForData();
    }

    checkLocalStorageForData() {
        if (localStorageController.isAvailable()) {
            const storedCompListData = localStorageController.getData('bft.compListData');
            if (storedCompListData && storedCompListData.dataValidUntil) {
                const storedCompListDataTimeDiff = moment().diff(storedCompListData.dataValidUntil, 'minutes');
                if (storedCompListDataTimeDiff > 0) {
                    this.getCompetitions();
                } else {
                    // using local storage data
                    this.setState({ compListData: storedCompListData.savedData, userData: this.props.userData, dataReady: true});
                }
            } else {
                this.getCompetitions();
            }
        } else {
            this.getCompetitions();
        }
    }

    async getCompetitions() {
		const res = await apiController.getCompList();
		if (res.status === 200) {
            localStorageController.storeData('bft.compListData', res.data);
			this.setState({compListData: res.data, userData: this.props.userData, dataReady: true});
		}
    }
    
    changeCompetition(evt) {
        this.props.history.push(`/${evt.currentTarget.getAttribute("data-compid")}`);
    }

    render() {
        if (this.state.dataReady) {
            return (
                <LoadingOverlay classNamePrefix='bf-page-loader-' active={!this.state.dataReady} fadeSpeed={Number(process.env.REACT_APP_LOADER_FADE_SPEED)} spinner={<PropagateLoader sizeUnit={"px"} size={24} />}>
                    <React.Fragment>
                        <Header compListData={this.state.compListData} userData={this.state.userData} />
                        
                        <div className="content-area">
                            <div className="container">
                                <div className="row">
                                    <div className="col">

                                        <div className="acomps-container">

                                            <div className="acomps-content content-responsive">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col">
                                                            <h2>Competitions</h2>

                                                            <div className="acomps-list">
                                                                {this.state.compListData.map((compItem, key) => (
                                                                    <div key={key} className="comp-list-tem" data-compid={compItem.competitionID} onClick={this.changeCompetition} title={compItem.description}>
                                                                        <div>{compItem.description}</div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </React.Fragment>
                </LoadingOverlay>
            );
        } else {
            return (
                <LoadingOverlay classNamePrefix='bf-page-loader-' active={!this.state.dataReady} fadeSpeed={Number(process.env.REACT_APP_LOADER_FADE_SPEED)} spinner={<PropagateLoader sizeUnit={"px"} size={24} />}></LoadingOverlay>
            );
        }
    }
}

export default withRouter(AvailableCompetitions)
