import React from 'react';
import { withRouter } from 'react-router-dom';
import Countdown from 'react-countdown-now';

import './CompetitionHeader.scss';


const timeLeftClosingRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render countdown completed text
      return <span className="closed">Round Closed</span>;
    } else {
        // Render countdown text
        if (days > 0) {
            return <span>Closing in {days} {(days === 1) ? 'day' : 'days'}</span>;
        } else if (days === 0 && hours > 0) {
            return <span className="closing pulse-warning">Closing in {hours} {(hours === 1) ? 'hour' : 'hours'}</span>;
        } else if (hours === 0 && minutes > 0) {
            return <span className="closing pulse-warning">Closing in {minutes} {(minutes === 1) ? 'min' : 'mins'}</span>;
        } else {
            return <span className="closing pulse-warning">Closing in {seconds} {(seconds === 1) ? 'sec' : 'secs'}</span>;
        }
    }
};

const timeLeftStartingSoonRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render countdown completed text
      return <span>Round Started</span>;
    } else {
        // Render countdown text
        if (days > 0) {
            return <span>Starting in {days} {(days === 1) ? 'day' : 'days'}</span>;
        } else if (days === 0 && hours > 0) {
            return <span className="soon pulse-success">Starting in {hours} {(hours === 1) ? 'hour' : 'hours'}</span>;
        } else if (hours === 0 && minutes > 0) {
            return <span className="soon pulse-success">Starting in {minutes} {(minutes === 1) ? 'min' : 'mins'}</span>;
        } else {
            return <span className="soon pulse-success">Starting in {seconds} {(seconds === 1) ? 'sec' : 'secs'}</span>;
        }
    }
};

class CompetitionHeader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isStartingSoon: this.checkIfStartingSoon(),
            roundIdx: this.props.roundIdx,
            compData: this.props.compData,
            dataReady: false,
            roundStartTime: new Date(this.props.compData.rounds[this.props.roundIdx].start_time*1000).toString(),
            roundEndTime: new Date(this.props.compData.rounds[this.props.roundIdx].end_time*1000).toString()
        }
    }

    componentDidMount() {
        this.setState({dataReady: true});
    }

    componentDidUpdate(prevProps) {
        if (this.props.roundIdx !== prevProps.roundIdx) {
            this.setState({
                isStartingSoon: this.checkIfStartingSoon(),
                compData: this.props.compData,
                roundIdx: this.props.roundIdx,
                roundStartTime: new Date(this.props.compData.rounds[this.props.roundIdx].start_time*1000).toString(),
                roundEndTime: new Date(this.props.compData.rounds[this.props.roundIdx].end_time*1000).toString()
            });
        }
    }

    checkIfStartingSoon() {
        const now = Math.floor(Date.now()/1000); // now
        const roundData = this.props.compData.rounds[this.props.roundIdx];
        return (roundData.start_time > now && roundData.end_time > now) ? true : false;
    }

    ordinalSuffixOf(i) {
        var j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }

    render() {
        if (this.state.compData && this.state.dataReady) {
            const round = this.state.compData.rounds[this.state.roundIdx];

            return (
                <div className="comp-header content-responsive">
                    <div className="container">
                        <div className="row">
                            <div className="col col-12 col-md-4">
                                <h2 title={this.state.compData.description}>{this.state.compData.description}</h2>
                                <div className="current-round">Current: <span>{round.description}</span></div>
                                <div className="closing-on">
                                    {this.state.isStartingSoon
                                        ? <Countdown date={this.state.roundStartTime} intervalDelay={5000} renderer={timeLeftStartingSoonRenderer} onComplete={() => this.props.refreshData()} />
                                        : <Countdown date={this.state.roundEndTime} intervalDelay={5000} renderer={timeLeftClosingRenderer} />}
                                </div>
                            </div>
                            <div className="col col-12 col-md-8">
                                <div className="comp-header-ranking-container">
                                    <div className="row">
                                        <div className="col-4 header-ranking" title="Overall Ranking">
                                            <div className="overall-rank">
                                                Overall Ranking
                                                <span className={(this.state.compData.leaderboard.userPosition > 0) ? 'display-rank' : ''}>{this.state.compData.leaderboard.userPosition > 0 ? this.ordinalSuffixOf(this.state.compData.leaderboard.userPosition) : '-'}</span>
                                            </div>
                                        </div>
                                        <div className="col-4 header-ranking" title="Round Ranking">
                                            <div className="round-rank">
                                                Round Ranking
                                                <span className={(this.state.compData.leaderboard.roundLadders[this.props.roundIdx].userPosition > 0) ? 'display-round-rank' : ''}>{this.state.compData.leaderboard.roundLadders[this.props.roundIdx].userPosition > 0 ? this.ordinalSuffixOf(this.state.compData.leaderboard.roundLadders[this.props.roundIdx].userPosition) : '-'}</span>
                                            </div>
                                        </div>
                                        <div className="col-4 header-ranking" title="Total Score">
                                            <div className="total-score">
                                                Total Score
                                                <span className={(this.state.compData.leaderboard.userScore > 0) ? 'display-score' : ''}>{this.state.compData.leaderboard.userScore > 0 ? this.state.compData.leaderboard.userScore : '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
          } else {
            return (
                <div className="comp-header content-responsive"></div>
            );
          }
    }
}

export default withRouter(CompetitionHeader)
