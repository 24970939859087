import React from 'react';
import { withRouter } from 'react-router-dom';

import './LeaderboardHeader.scss';


class LeaderboardHeader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            roundIdx: 0,
            leaderboardData: this.props.leaderboardData,
            dataReady: false
        }
    }

    componentDidMount() {
        this.setState({roundIdx: this.props.roundIdx, dataReady: true});
    }

    componentDidUpdate(prevProps) {
        if (this.props.roundIdx !== prevProps.roundIdx) {
            this.setState({leaderboardData: this.props.leaderboardData, roundIdx: this.props.roundIdx});
        }
    }

    ordinalSuffixOf(i) {
        var j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }

    render() {
        if (this.state.dataReady && this.state.leaderboardData) {
            // const lb = this.state.leaderboardData;

            return (
                <div className="lead-header content-responsive">

                    <div className="container">
                        <div className="navigation-container">
                            <div data-display="overall" onClick={this.props.changeDisplay} className={this.props.displayType === 'overall' ? "current nav-item" : "nav-item"} title="Overall Leaderboard">Overall</div>
                            <div data-display="rounds" onClick={this.props.changeDisplay} className={this.props.displayType === 'rounds' ? "current nav-item" : "nav-item"} title="Leaderboard by Rounds">By Round</div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col col-12 col-md-4">
                                <h2>LEADERBOARD</h2>
                            </div>
                            <div className="col col-12 col-md-8">
                                <div className="lead-header-ranking-container">
                                    <div className="row">
                                        <div className="col-4 header-ranking">
                                            <div className="overall-rank" title="Overall Ranking">
                                                Overall Ranking
                                                <span className={(this.state.leaderboardData.userPosition > 0) ? 'display-rank' : ''}>{this.state.leaderboardData.userPosition > 0 ? this.ordinalSuffixOf(this.state.leaderboardData.userPosition) : '-'}</span>
                                            </div>
                                        </div>
                                        <div className={this.props.displayType === 'overall' ? 'col-4 header-ranking opacity-03' : 'col-4 header-ranking'} title="Round Rankng">
                                            <div className="round-rank">
                                                Round Ranking
                                                <span className={(this.state.leaderboardData.roundLadders[this.props.roundIdx].userPosition > 0) ? 'display-round-rank' : ''}>
                                                    <span style={this.props.displayType === 'overall' ? {display: 'inline'} : {display: 'none'}}>-</span>
                                                    <span style={this.props.displayType === 'rounds' ? {display: 'inline'} : {display: 'none'}}>{this.state.leaderboardData.roundLadders[this.props.roundIdx].userPosition > 0 ? this.ordinalSuffixOf(this.state.leaderboardData.roundLadders[this.props.roundIdx].userPosition) : '-'}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4 header-ranking" title="Total Score">
                                            <div className="total-score">
                                                Total Score
                                                <span className={(this.state.leaderboardData.userScore > 0) ? 'display-score' : ''}>{this.state.leaderboardData.userScore > 0 ? this.state.leaderboardData.userScore : '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
          } else {
            return (
                <div className="lead-header content-responsive"></div>
            );
          }
    }
}

export default withRouter(LeaderboardHeader)
