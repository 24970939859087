import React from 'react';
import { withRouter } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import PropagateLoader from 'react-spinners/PropagateLoader';
import moment from 'moment';

import './Prizes.scss';

// Controllers

import apiController from '../../controllers/ApiController';
import localStorageController from '../../controllers/LocalStorageController';

// Components

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import NoAccess from '../NoAccess/NoAccess';


class Prizes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            compId: null,
            userData: {},
            compData: {},
            dataReady: false
        }
    }

    componentDidMount() {
        this.checkLocalStorageForData();
    }

    checkLocalStorageForData() {
        if (localStorageController.isAvailable()) {
            const storedUserData = localStorageController.getData('bft.userData');
            const storedCompData = localStorageController.getData('bft.compData');
            if (storedUserData && storedUserData.dataValidUntil && storedCompData && storedCompData.dataValidUntil) {
                const userDataTimeDiff = moment().diff(storedUserData.dataValidUntil, 'minutes');
                const compDataTimeDiff = moment().diff(storedCompData.dataValidUntil, 'minutes');
                if (userDataTimeDiff > 0 || compDataTimeDiff > 0) {
                    this.getUserDataAndCompDataForPrizes();
                } else {
                    if (Number(this.props.match.params.compid) === Number(storedCompData.savedData.competitionID)) { // check if local storage comp id is same as browser URL compid, if so use localstorage data, otherwise force get fresh data
                        setTimeout(() => { // timeout here to act as a small "nice transition"
                        this.setState({ compId: this.props.match.params.compid, userData: storedUserData.savedData, compData: storedCompData.savedData, dataReady: true });
                        }, 500);
                    } else {
                        this.getUserDataAndCompDataForPrizes();
                    }
                }
            } else {
                this.getUserDataAndCompDataForPrizes();
            }
        } else {
            this.getUserDataAndCompDataForPrizes();
        }
    }

    async getUserDataAndCompDataForPrizes() {
        const compId = this.props.match.params.compid;

		const res1 = await apiController.getUserDetails(compId);
		if (res1.status === 200) {
            localStorageController.storeData('bft.userData', res1.data);
            this.setState({userData: res1.data});
            
            const res2 = await apiController.getCompDetails(compId);
            if (res2.status === 200) {
                localStorageController.storeData('bft.compData', res2.data);
                this.setState({compData: res2.data, dataReady: true});
            }
		}
    }

    render() {
        if (this.state.dataReady && this.state.userData.isAllowedInComp) { // If we are allowed in comp

            return (
                <LoadingOverlay classNamePrefix='bf-page-loader-' active={!this.state.dataReady} fadeSpeed={Number(process.env.REACT_APP_LOADER_FADE_SPEED)} spinner={<PropagateLoader sizeUnit={"px"} size={24} />}>
                    <React.Fragment>
                        <Header userData={this.state.userData} />

                        <div className="content-area">
                            <div className="container">
                                <div className="row">
                                    <div className="col">

                                        <div className="prizes-container">

                                            <div className="prizes-content content-responsive">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col">
                                                            <h2>Prizes</h2>

                                                            <div className="prize-data" dangerouslySetInnerHTML={{ __html: this.state.compData.prizes }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </React.Fragment>
                </LoadingOverlay>
            );

          } else if (this.state.dataReady && !this.state.userData.isAllowedInComp) { // If not allowed in comp
            return (
                <LoadingOverlay classNamePrefix='bf-page-loader-' active={!this.state.dataReady} fadeSpeed={Number(process.env.REACT_APP_LOADER_FADE_SPEED)} spinner={<PropagateLoader sizeUnit={"px"} size={24} />}>
                    <NoAccess />
                </LoadingOverlay>
            );
          } else {
            return (
                <LoadingOverlay classNamePrefix='bf-page-loader-' active={!this.state.dataReady} fadeSpeed={Number(process.env.REACT_APP_LOADER_FADE_SPEED)} spinner={<PropagateLoader sizeUnit={"px"} size={24} />}></LoadingOverlay>
            );
          }
    }
}

export default withRouter(Prizes)
