import React from 'react';
import { withRouter } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import PulseLoader from 'react-spinners/PulseLoader';

import './CompetitionBody.scss';

// Controllers

import validationController from '../../../controllers/FormValidationController';
import apiController from '../../../controllers/ApiController';


class CompetitionBody extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            roundIdx: this.props.roundIdx,
            compData: this.props.compData,
            isRoundOpen: this.checkIfRoundIsOpen(),
            roundResults: this.checkIfRoundHasResults(),
            tipsData: this.prePopulateSelections(), // building initial form field control names and pre-populating with values
            submittingInProgress: false,
            submissionSuccess: false,
            formError: false,
            dataReady: false
        }

        this.handleSubmitTips = this.handleSubmitTips.bind(this);
        this.handleTieBreakerChange = this.handleTieBreakerChange.bind(this);
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            dataReady: true
        });
        this.isOverflown();
    }

    componentDidUpdate(prevProps) {
        if (this.props.roundIdx !== prevProps.roundIdx) {
            if (document.getElementById("userTips") !== null) {
                document.getElementById("userTips").reset(); // Resetting input fields and clears values on round change.
            }
            this.setState({
                isRoundOpen: this.checkIfRoundIsOpen(),
                roundResults: this.checkIfRoundHasResults(),
                submissionSuccess: false,
                tipsData: this.prePopulateSelections(),  // reset and building initial form field control names and pre-populating with values
                formError: validationController.resetFormValidation(), // Resetting any form valdation and setting formError to false on round change.
                compData: this.props.compData,
                roundIdx: this.props.roundIdx
            });
            if (document.getElementById('scrollingContentContainer') !== null) {
                document.getElementById('scrollingContentContainer').scrollTop = 0; // Scroll back to top of inner container
            }
        }
    }

    checkIfRoundHasResults() {
        return this.props.compData.rounds[this.props.roundIdx].tie_answer ? true : false;
    }

    checkIfRoundIsOpen() {
        const now = Math.floor(Date.now()/1000);
        const roundObj = this.props.compData.rounds[this.props.roundIdx];
        return (roundObj.start_time < now && roundObj.end_time > now) ? true : false;
    }

    prePopulateSelections() {
        // because of dynamic form fields, we are dynamically creating initial props with selection id's and values received from API so React can match the name/key and values
        let selectionBuilder = {};
        const roundSelections = this.props.compData.rounds[this.props.roundIdx].selections;
        let i = 0;
        for (i === 0; i < roundSelections.length; i++) {
            if (roundSelections[i].user_tip) {
                selectionBuilder = {
                    ...selectionBuilder,
                    [`selectiontid-${roundSelections[i].selectionID}`]: roundSelections[i].user_tip
                }
            }
        }
        
        return {
            ...selectionBuilder,
            compId: this.props.compId,
            [`tiebreakertip-${this.props.compData.rounds[this.props.roundIdx].roundID}`]: this.props.compData.rounds[this.props.roundIdx].tie_breaker_tip
        }
    }

    handleSelectionChange(e) {
        this.setState({ tipsData: {
            ...this.state.tipsData, [e.currentTarget.querySelector("input").name]: e.currentTarget.querySelector("input").value
        } });
        validationController.validateIndividualField(e.currentTarget.querySelector("input").name);
    }

    handleTieBreakerChange(e) {
        this.setState({ tipsData: {
            ...this.state.tipsData, [e.currentTarget.name]: e.currentTarget.value
        } });
        validationController.validateIndividualField(e.currentTarget.name);
    }

    handleSubmitTips(e) {
        e.preventDefault();

        if (this.state.submittingInProgress) { // Check if we are already submitting..
            console.log('we are already submitting..');
            return;
        }

        let hasError = validationController.validateForm(this.state.tipsData);
        this.setState({formError: hasError});

        console.log('...Submitting...', 'Round Open?', this.state.isRoundOpen, 'Form hasError state:', hasError, 'this.state.tipsData:', this.state.tipsData);

        if (!this.state.isRoundOpen) { // if round is closed.
            alert('Sorry, but this round is now closed!');
            return;
        }

        if (!hasError) {
            this.setState({submittingInProgress: true});

            apiController.updateTipps(
                this.state.tipsData,
                (success) => {
                    console.log("tips post success:", success);
                    apiController.updateTieBreak(
                        this.state.tipsData,
                        (success) => {
                            setTimeout(() => {
                                console.log("tie break post success:", success);
                                this.setState({submittingInProgress: false, submissionSuccess: true});
                                this.props.refreshData(); // force parent Competition.js component to re-fetch data
                                setTimeout(() => {
                                    this.setState({submissionSuccess: false});
                                }, 2200);
                            }, 1700);
                        },
                        (error) => {
                            setTimeout(() => {
                                console.log("tie break post error", error);
                                this.setState({submittingInProgress: false});
                            }, 1700);
                    })
                },
                (error) => console.log("tips post error", error)
            )
        }
    }

    isOverflown() {
        setTimeout(() => {
            const scrollContainer = document.getElementById('scrollingContentContainer');
            const scrollContent = document.getElementById('scrollingContent');
            const scrollIndicator = document.getElementById('scrollingIndicator');
            if (scrollContainer !== null && scrollContent !== null && scrollIndicator !== null) {
                if (scrollContent.clientHeight > scrollContainer.clientHeight) {
                    scrollIndicator.classList.add('show-indicator');
                }
            }
        }, 700);
    }


    render() {
        const now = Math.floor(Date.now()/1000); // now
        console.log('roundResults', this.state.roundResults, 'isRoundOpen', this.state.isRoundOpen);
        if (this.state.dataReady && !this.state.isRoundOpen && !this.state.roundResults && this.props.compData.rounds[this.props.roundIdx].start_time > now) { // If round is starting soon and has no results, display interim message
            return (
                <div className="comp-body-footer-wrapper">
                    <div className="comp-body content-responsive">
                        <div className="no-data-message">{this.props.compData.rounds[this.props.roundIdx].description} is <span className="starting">starting soon</span>. Please check back later</div>
                    </div>
                    <div className="comp-footer content-responsive">&nbsp;</div>
                </div>
            );
        } else if (this.state.dataReady && (this.state.isRoundOpen || !this.state.isRoundOpen) && !this.state.roundResults && this.props.compData.rounds[this.props.roundIdx].start_time < now) { // If round is open and has no results, display tipping selections
            const round = this.state.compData.rounds[this.state.roundIdx];

            return (
                <div className="comp-body-footer-wrapper">

                    <form id="userTips" onSubmit={this.handleSubmitTips}>
                        <fieldset disabled={!this.state.isRoundOpen}>

                            <div id="scrollingContentContainer" className="comp-body content-responsive inner-scroll">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div id="scrollingContent" className="inner-body-content">
                                                {round.selections[0].description.toLowerCase().indexOf('race') === -1 &&
                                                    <p className="disclaimer-text">*Odds displayed are accurate as at 48 hours before commencement of the Round. For current odds <a target="_blank" href="https://www.betfair.com.au/exchange/plus/australian-rules/competition/11897406">click here</a>.</p>
                                                }
                                                {round.selections.map((selection, key) => (
                                                    <div key={key} className="selection">
                                                        <div className="form-group">
                                                            {selection.description.indexOf('#') === -1
                                                                ? <div className="s-desc">{selection.description}</div>
                                                                : <div className="s-desc">{selection.description.replace(/\#.*/,'')}</div>
                                                            }
                                                            <div className="s-wrapper validate-field">
                                                                {selection.description.indexOf('#') === -1
                                                                    ? <label htmlFor={`selectiontid-${selection.selectionID}`}>{selection.options.split(/,/)[0]} vs {selection.options.split(/,/)[1]}</label>
                                                                    : <label htmlFor={`selectiontid-${selection.selectionID}`}>#{selection.description.split(/#/)[1]}</label>
                                                                }
                                                                <div className="btn-group btn-group-toggle float-right" data-toggle="buttons">
                                                                    <label className={(selection.user_tip === selection.options.split(/,/)[0].replace(/\$.*/,'').replace(/((\s*\S+)*)\s*/, "$1")) ? 'btn btn-radio active' : 'btn btn-radio'} onClick={this.handleSelectionChange}>
                                                                        <input type="radio" name={`selectiontid-${selection.selectionID}`} value={selection.options.split(/,/)[0].replace(/\$.*/,'').replace(/((\s*\S+)*)\s*/, "$1")} autoComplete="off" /> {selection.options.split(/,/)[0].replace(/\$.*/,'')}
                                                                    </label>
                                                                    <label className={(selection.user_tip === selection.options.split(/,/)[1].replace(/\$.*/,'').replace(/((\s*\S+)*)\s*/, "$1")) ? 'btn btn-radio active' : 'btn btn-radio'} onClick={this.handleSelectionChange}>
                                                                        <input type="radio" name={`selectiontid-${selection.selectionID}`} value={selection.options.split(/,/)[1].replace(/\$.*/,'').replace(/((\s*\S+)*)\s*/, "$1")} autoComplete="off" /> {selection.options.split(/,/)[1].replace(/\$.*/,'')}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className="form-group">
                                                    <div className="s-desc">Tie Breaker</div>
                                                    <div className="s-wrapper validate-field">
                                                        <label htmlFor={`tiebreakertip-${round.roundID}`}>{round.tie_breaker}</label>
                                                        
                                                        <input 
                                                            className="tie-breaker float-right" 
                                                            type="number"
                                                            step="0.01"
                                                            name={`tiebreakertip-${round.roundID}`} 
                                                            value={this.state.tipsData[`tiebreakertip-${round.roundID}`]} 
                                                            onChange={this.handleTieBreakerChange}
                                                            placeholder={round.selections[0].description.toLowerCase().indexOf('race') === -1 ? "Points Margin" : "BSP Prediction ($)"}
                                                            autoComplete="off" 
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Sticky mode="bottom" boundaryElement=".comp-body-footer-wrapper" style={{ position: 'relative' }} hideOnBoundaryHit={false}>
                                <div className="comp-footer content-responsive">
                                    <div className="container">
                                        <div id="scrollingIndicator" className="scroll-down-indicator"><span></span></div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="footer-controls">
                                                    <button className={this.state.isRoundOpen ? 'btn btn-primary float-right' : 'btn btn-primary float-right hidden'}>
                                                        <PulseLoader sizeUnit={"px"} size={9} color={'#ffffff'} loading={this.state.submittingInProgress} />
                                                        <span className={this.state.submittingInProgress ? 'hidden' : ''}>Save Tips</span>
                                                    </button>
                                                    <div className="message-box">
                                                        <span className={(this.state.isRoundOpen) ? 'form-error float-right hidden' : 'form-error waiting-for-result float-right'}>
                                                            <span className="warning-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20">
                                                                    <path id="warning-icon" d="M10 0a10 10 0 1 0 10 10A10 10 0 0 0 10 0zm1 16H9v-2h2zm0-4H9V4h2z" />
                                                                </svg>
                                                            </span>&nbsp; Round is closed. Please check back later for results.
                                                        </span>
                                                        <span className={(this.state.formError) ? 'form-error float-right' : 'form-error float-right hidden'}>
                                                            <span className="warning-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20">
                                                                    <path id="warning-icon" d="M10 0a10 10 0 1 0 10 10A10 10 0 0 0 10 0zm1 16H9v-2h2zm0-4H9V4h2z" />
                                                                </svg>
                                                            </span>&nbsp; Please fill out all fields before submitting.
                                                        </span>
                                                        <span className={(this.state.submissionSuccess) ? 'form-success showing float-right' : 'form-success float-right'}>
                                                            <span className="success-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 44 44" enableBackground="new 0 0 44 44">
                                                                    <path id="success-icon" d="m22,0c-12.2,0-22,9.8-22,22s9.8,22 22,22 22-9.8 22-22-9.8-22-22-22zm12.7,15.1l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.6-0.1-0.7-0.3l-7.8-8.4-.2-.2c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.3 0.4,0.9 0,1.3z" />
                                                                </svg>
                                                            </span>&nbsp; Your selections have been saved.
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Sticky>

                        </fieldset>
                    </form>

                </div>
            );
        } else if (this.state.dataReady && !this.state.isRoundOpen && this.state.roundResults && this.props.compData.rounds[this.props.roundIdx].end_time < now) { // If round is closed and has results, display results
            const round = this.state.compData.rounds[this.state.roundIdx];
            let totalRoundScore = 0;

            for (var i = 0; i < round.selections.length; i++) {
                if(round.selections[i].user_tip === round.selections[i].correct_tip) {
                    totalRoundScore = totalRoundScore + round.selections[i].correct_tip_price - 1;
                }
            }

            return (
                <div className="comp-body-footer-wrapper">

                    <div id="scrollingContentContainer" className="comp-body content-responsive inner-scroll">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div id="scrollingContent" className="inner-body-content">
                                    <div className="results-header float-right">
                                        <div className="your-pick">Your Pick</div>
                                        <div className="result">Result</div>
                                        {round.selections[0].description.indexOf('#') === -1 &&
                                            <div className="closing-odds">Closing Price</div>
                                        }
                                        <div className="points">Points</div>
                                    </div>

                                        {round.selections.map((selection, key) => (
                                            <div key={key} className="selection">
                                                <div className="form-group">
                                                    {selection.description.indexOf('#') === -1
                                                        ? <div className="s-desc">{selection.description}</div>
                                                        : <div className="s-desc">{selection.description.replace(/\#.*/,'')}</div>
                                                    }
                                                    <div className="s-wrapper">
                                                        {selection.description.indexOf('#') === -1
                                                            ? <label htmlFor={`selectiontid-${selection.selectionID}`}>{selection.options.split(/,/)[0].replace(/\$.*/,'').replace(/((\s*\S+)*)\s*/, "$1")} vs {selection.options.split(/,/)[1].replace(/\$.*/,'').replace(/((\s*\S+)*)\s*/, "$1")}</label>
                                                            : <label htmlFor={`selectiontid-${selection.selectionID}`}>#{selection.description.split(/#/)[1]}</label>
                                                        }
                                                        <div className="result-data float-right">
                                                            <div className="your-pick">{selection.user_tip ? selection.user_tip : '-'}</div>
                                                            <div className={(selection.user_tip === selection.correct_tip) ? 'result-pick correct' : 'result-pick wrong'}>{selection.correct_tip}</div>
                                                            {round.selections[0].description.indexOf('#') === -1 &&
                                                                <div className="closing-odds">{selection.correct_tip_price}</div>
                                                            }
                                                            <div className={(selection.user_tip === selection.correct_tip) ? 'points correct' : 'points wrong'}>{(selection.user_tip === selection.correct_tip) ? (selection.correct_tip_price - 1).toFixed(2) : '0'} <span className="mobile-point-text">&nbsp;Point</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <div className="form-group">
                                            <div className="s-desc">Tie Breaker</div>
                                            <div className="results-header float-right">
                                                <div className="your-pick">Your Tie Breaker</div>
                                                <div className="result">The Answer</div>
                                                <div className="difference">Difference</div>
                                            </div>
                                            <div className="s-wrapper">
                                                <label htmlFor={`tiebreakertip-${round.roundID}`}>{round.tie_breaker}</label>
                                                <div className="result-data float-right">
                                                    <div className="your-pick">{round.tie_breaker_tip ? round.tie_breaker_tip : '-'}</div>
                                                    <div className="result-pick">{round.tie_answer}</div>
                                                    <div className="points">{Math.abs(round.tie_breaker_tip - round.tie_answer)}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Sticky mode="bottom" boundaryElement=".comp-body-footer-wrapper" style={{ position: 'relative' }} hideOnBoundaryHit={false}>
                        <div className="comp-footer content-responsive">
                            <div className="container">
                                <div id="scrollingIndicator" className="scroll-down-indicator"><span></span></div>
                                <div className="row">
                                    <div className="col">
                                        <div className="footer-controls">
                                            <div className="total-round-score">Total Round Score: <span>{totalRoundScore.toFixed(2)}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Sticky>

                </div>
            );
        }/* else if (this.state.dataReady && !this.state.isRoundOpen && !this.state.roundResults && this.props.compData.rounds[this.props.roundIdx].end_time < now) { // If round is closed and has no results, display interim message
            return (
                <div className="comp-body-footer-wrapper">
                    <div className="comp-body content-responsive">
                        <div className="no-data-message">{this.props.compData.rounds[this.props.roundIdx].description} is <span className="closed">closed</span>. Please check back later for results</div>
                    </div>
                    <div className="comp-footer content-responsive">&nbsp;</div>
                </div>
            );
        }*/ else {
            return (
                <div className="comp-body-footer-wrapper">
                    <div className="comp-body content-responsive">&nbsp;</div>
                    <div className="comp-footer content-responsive">&nbsp;</div>
                </div>
            );
          }
    }
}

export default withRouter(CompetitionBody)
