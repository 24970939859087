import moment from 'moment';

function storeData(key, data) {
    const mergeData = {
        savedData: data,
        dataValidUntil: moment().add(process.env.REACT_APP_LOCALSTORAGE_CACHE_HOLD_TIME, 'minutes')
    };

    localStorage.setItem(key, JSON.stringify(mergeData));
}

function getData(key) {
    if (localStorage.hasOwnProperty(key)) {
        let retrievedData = localStorage.getItem(key);

        try { // parse the localStorage string
            retrievedData = JSON.parse(retrievedData);
            return retrievedData;
        } catch (e) { // handle empty string
            return retrievedData;
        }
    }
}

function deleteData(key) {
    if (localStorage.hasOwnProperty(key)) {
        localStorage.removeItem(key);
    }
}

function clearLocalStorage() {
    localStorage.clear();
}

function isAvailable() {
    var test = 'bf.test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}


export default {
    storeData,
    getData,
    deleteData,
    isAvailable,
    clearLocalStorage
}