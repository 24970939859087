import React from 'react';
import { Link } from 'react-router-dom';

import './Navigation.scss';


export default class Navigation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            navItems: {
                tipsActive: '',
                leaderboardActive: '',
                prizesActive: '',
                termsActive: ''
            },
            dataReady: false
        }
    }

    componentDidMount() {
        if (this.props.match.params.compid) {
            this.flagNavItemAsCurrent();
        }
    }

    flagNavItemAsCurrent() {
        const currentPath = this.props.match.path;

        switch(currentPath) {
            case '/:compid':
                this.setState({ navItems: { tipsActive: 'current' }});
                break;
            case '/:compid/leaderboard':
                this.setState({ navItems: { leaderboardActive: 'current' }});
                break;
            case '/:compid/prizes':
                this.setState({ navItems: { prizesActive: 'current' }});
                break;
            case '/:compid/terms-and-conditions':
                this.setState({ navItems: { termsActive: 'current' }});
                break;
            default:
                break;
        }

        this.setState({ dataReady: true });
    }

    render() {
        if (this.state.dataReady && this.props.match.params.compid) {
            return (
                <div className="navigation-container">
                    <Link to={`/${this.props.match.params.compid}/`} className={`${this.state.navItems.tipsActive} nav-item`} title="Tips">Tips</Link>
                    <Link to={`/${this.props.match.params.compid}/leaderboard`} className={`${this.state.navItems.leaderboardActive} nav-item`} title="Leaderboard">Leaderboard</Link>
                    <Link to={`/${this.props.match.params.compid}/prizes`} className={`${this.state.navItems.prizesActive} nav-item`} title="Prizes">Prizes</Link>
                    <Link to={`/${this.props.match.params.compid}/terms-and-conditions`} className={`${this.state.navItems.termsActive} nav-item`} title="Terms &amp; Conditions">T&amp;C</Link>
                </div>
            );
        } else {
            return (<div className="navigation-container"></div>);
        }
    }
}