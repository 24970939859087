import React from 'react';

const Regs = () => (
  <div className="footer-texts">
    <div className="footer-license-nt">
      Betfair Pty Limited is licensed and regulated by the Northern Territory Government of Australia.
    </div>
    <div className="footer-license-sa">
      Betfair Pty Limited&apos;s gambling operations are governed by its Responsible Gambling Code of Conduct and for South Australian residents by the South Australian Responsible Gambling
      &nbsp;
      <a href={process.env.REACT_APP_SA_REGS}> Code of Practice</a>
      .
    </div>
    <div className="footer-responsible-gambling">
      Think! About your choices. Stay in control. Leave before you lose it. Gamble responsibly. Call Gambling Help 1800 858 858
      &nbsp;
      <a
        href="https://www.gamblinghelponline.org.au"
        rel="noopener noreferrer"
        target="_blank"
      >
        www.gamblinghelponline.org.au
      </a>
    </div>
    <div className="footer-links">
      <div className="link-item">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={process.env.REACT_APP_TC_LINK}
        >
          Terms &amp; Conditions
        </a>
      </div>
      <div className="link-item">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={process.env.REACT_APP_RES_GAMBLING}
        >
          Responsible Gambling
        </a>
      </div>
      <div className="link-item">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={process.env.REACT_APP_PRIVACY_POLICY}
        >
          Privacy Policy
        </a>
      </div>
      <div className="link-item">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={process.env.REACT_APP_COOKIE_POLICY}
        >
          Cookie
        </a>
      </div>
      <div className="link-item">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={process.env.REACT_APP_RULES_REG}
        >
          Rules &amp; Regulations
        </a>
      </div>
      <div className="link-item">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={process.env.REACT_APP_DIRECT_RESOLUTION}
        >
          Dispute Resolution
        </a>
      </div>
    </div>
  </div>
);

export default Regs;
