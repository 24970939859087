import React from 'react';
import { withRouter } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import PropagateLoader from 'react-spinners/PropagateLoader';
import moment from 'moment';

import './Leaderboard.scss';

// Controllers

import apiController from '../../controllers/ApiController';
import localStorageController from '../../controllers/LocalStorageController';

// Components

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import NoAccess from '../NoAccess/NoAccess';
import LeaderboardHeader from './LeaderboardHeader/LeaderboardHeader';
import LeaderboardNav from './LeaderboardNav/LeaderboardNav';
import LeaderboardBody from './LeaderboardBody/LeaderboardBody';


class Leaderboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            compId: null,
            displayType: "overall",
            userData: {},
            compData: {},
            leaderboardData: {},
            currentRoundArrayIdx: 0,
            dataReady: false
        }

        this.changeDisplayHandler = this.changeDisplayHandler.bind(this);
        this.changeRoundHandler = this.changeRoundHandler.bind(this);
    }

    componentDidMount() {
        console.log('Leaderboard Props', this.props);
        this.checkLocalStorageForData();
    }

    checkLocalStorageForData() {
        if (localStorageController.isAvailable()) {
            const storedUserData = localStorageController.getData('bft.userData');
            const storedCompData = localStorageController.getData('bft.compData');
            if (storedUserData && storedUserData.dataValidUntil && storedCompData && storedCompData.dataValidUntil) {
                const userDataTimeDiff = moment().diff(storedUserData.dataValidUntil, 'minutes');
                const compDataTimeDiff = moment().diff(storedCompData.dataValidUntil, 'minutes');
                if (userDataTimeDiff > 0 || compDataTimeDiff > 0) {
                    this.getCompAndLeaderboardData();
                } else {
                    if (Number(this.props.match.params.compid) === Number(storedCompData.savedData.competitionID)) { // check if local storage comp id is same as browser URL compid, if so use localstorage data, otherwise force get fresh data
                        setTimeout(() => { // timeout here to act as a small "nice transition"
                        this.setState({ compId: this.props.match.params.compid, userData: storedUserData.savedData, compData: storedCompData.savedData, leaderboardData: storedCompData.savedData.leaderboard, dataReady: true });
                        }, 500);
                    } else {
                        this.getCompAndLeaderboardData();
                    }
                }
            } else {
                this.getCompAndLeaderboardData();
            }
        } else {
            this.getCompAndLeaderboardData();
        }
    }

    async getCompAndLeaderboardData() {
        const compId = this.props.match.params.compid;
        console.log('compId', compId);

		const res1 = await apiController.getUserDetails(compId);
		if (res1.status === 200) {
            localStorageController.storeData('bft.userData', res1.data);
            this.setState({userData: res1.data});

            const res2 = await apiController.getCompDetails(compId);
            if (res2.status === 200) {
                localStorageController.storeData('bft.compData', res2.data);
                this.setState({ compData: res2.data, leaderboardData: res2.data.leaderboard, dataReady: true });
            }
		}
    }

    changeDisplayHandler(evt) {
        this.setState({ displayType: evt.currentTarget.getAttribute("data-display") });
    }

    changeRoundHandler(evt) {
        this.setState({ currentRoundArrayIdx: evt.currentTarget.getAttribute("data-round") });
    }

    render() {
        if (this.state.dataReady && (this.state.userData && this.state.leaderboardData) && this.state.userData.isAllowedInComp) { // If we are allowed in comp
            console.log('Leaderboard!');

            return (
                <LoadingOverlay classNamePrefix='bf-page-loader-' active={!this.state.dataReady} fadeSpeed={Number(process.env.REACT_APP_LOADER_FADE_SPEED)} spinner={<PropagateLoader sizeUnit={"px"} size={24} />}>
                    <React.Fragment>
                        <Header userData={this.state.userData} />

                        <div className="content-area">
                            <div className="container">
                                <div className="row">
                                    <div className="col">

                                        <div className="leaderboard-container">

                                            <LeaderboardHeader leaderboardData={this.state.leaderboardData} roundIdx={this.state.currentRoundArrayIdx} changeDisplay={this.changeDisplayHandler} displayType={this.state.displayType} />
                                            <LeaderboardNav compData={this.state.compData} roundIdx={this.state.currentRoundArrayIdx} changeRound={this.changeRoundHandler} displayType={this.state.displayType} />
                                            <LeaderboardBody userData={this.state.userData} compData={this.state.compData} leaderboardData={this.state.leaderboardData} roundIdx={this.state.currentRoundArrayIdx} displayType={this.state.displayType} />

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </React.Fragment>
                </LoadingOverlay>
            );

          } else if (this.state.dataReady && (this.state.userData && this.state.leaderboardData) && !this.state.userData.isAllowedInComp) { // If not allowed in comp
            return (
                <LoadingOverlay classNamePrefix='bf-page-loader-' active={!this.state.dataReady} fadeSpeed={Number(process.env.REACT_APP_LOADER_FADE_SPEED)} spinner={<PropagateLoader sizeUnit={"px"} size={24} />}>
                    <NoAccess />
                </LoadingOverlay>
            );
          } else {
            return (
                <LoadingOverlay classNamePrefix='bf-page-loader-' active={!this.state.dataReady} fadeSpeed={Number(process.env.REACT_APP_LOADER_FADE_SPEED)} spinner={<PropagateLoader sizeUnit={"px"} size={24} />}></LoadingOverlay>
            );
          }
    }
}

export default withRouter(Leaderboard)
