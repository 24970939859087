import React from 'react';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';

import './NoAccess.scss';

// Controllers

import authController from '../../controllers/AuthController';

// Components

import Footer from '../Footer/Footer';


class NoAccess extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: authController.Authenticated(),
            userData: {},
            dataReady: false
        }
    }

    componentDidMount() {
    }

    logout() {
        if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
            Cookies.remove('ssoid', {domain: process.env.REACT_APP_LOGOUT_DOMAIN, path: '/'});
            document.forms.logout.submit();
        }

        if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
            Cookies.remove('ssoid');
            console.log('Logged out');
            window.location = process.env.REACT_APP_PUBLIC_URL;
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="no-access-container">

                    <div className="top-header-bar">
                        <div className="container">
                            <div className="row">

                                <div className="col-6">
                                    <a href={process.env.REACT_APP_PUBLIC_URL} className="betfair-logo float-left" title="Betfair | Tipping">&nbsp;</a>
                                </div>

                                <div className="col-6">
                                    <div className="login-actions float-right">
                                        <a href={`${process.env.REACT_APP_LOGIN_URL}?product=${process.env.REACT_APP_PRODUCT_KEY}&redirectMethod=GET&url=${process.env.REACT_APP_PUBLIC_URL}${this.props.match.url}`} className={this.state.isAuthenticated ? 'login-link hidden' : 'login-link '} title="Login">Login</a>

                                        <form id="logout" action={process.env.REACT_APP_LOGOUT_URL} className={this.state.isAuthenticated ? 'logout-link' : 'logout-link hidden'}>
                                        <input type="hidden" name="product" value="betfair-tipping" />
                                        <input type="hidden" name="redirectMethod" value="POST" />
                                        <input type="hidden" name="url" value={process.env.REACT_APP_LOGOUT_REDIRECT_URL} />
                                        <div onClick={this.logout} className="logout-button" title="Logout">
                                            <span className="header-button-text">Logout</span>
                                        </div>
                                    </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="bs-container container">
                        <div className="row">
                            <div className="col">

                                <div className="no-access-content">
                                    <h2>Unfortunately you have not been granted access for this page</h2>
                                    <hr />
                                    <p>Please contact customer support<br />if you have any queries.</p>
                                    <a href={process.env.REACT_APP_CONTACT_SUPPORT} className="btn btn-primary btn-block">Contact Support</a>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="no-access-footer-wrapper">
                        <Footer />
                    </div>

                </div>
            </React.Fragment>
        );
    }

}

export default withRouter(NoAccess)