import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import PropagateLoader from 'react-spinners/PropagateLoader';
import TagManager from 'react-gtm-module'

// Controllers

import apiController from './controllers/ApiController';

// Components

import AvailableCompetitions from './components/AvailableCompetitions/AvailableCompetitions';
import Competition from './components/Competition/Competition';
import Leaderboard from './components/Leaderboard/Leaderboard';
import Prizes from './components/Prizes/Prizes';
import TermsConditions from './components/TermsConditions/TermsConditions';
import NoAccess from './components/NoAccess/NoAccess';
import Login from './components/Login/Login';

const tagManagerArgs = {
	gtmId: 'GTM-5WC7B3T'
}

TagManager.initialize(tagManagerArgs)

class App extends Component {
  	constructor(props) {
    	super(props);

    	this.state = {
			userData: {},
			isAuthenticated: null,
			dataReady: false
		};
	}

  	componentDidMount() {
		this.getLoginInfoAndUserData();
	}

	async getLoginInfoAndUserData() {
		console.log('.. checking login ..');
		const res = await apiController.getUserDetails();
		if (res.status === 200) {
			this.setState({isAuthenticated: res.data.ssoLoginStatus, userData: res.data, dataReady: true});
			console.log('principalId: ', res.data.principalId);
		}
	}

  	render() {
		if (!this.state.dataReady) {
			return (
				<LoadingOverlay classNamePrefix='bf-page-loader-' active={!this.state.dataReady} fadeSpeed={Number(process.env.REACT_APP_LOADER_FADE_SPEED)} spinner={<PropagateLoader sizeUnit={"px"} size={24} />}></LoadingOverlay>
			);
		}

		if (this.state.isAuthenticated && this.state.dataReady) {
			console.log('user is authenticated!');
			return (
				<div className="betfair-tipping">
					<Router>
						<React.Fragment>
	
							<div className="bf-content">
								<Switch>
									<Route exact path="/" render={() => <AvailableCompetitions userData={this.state.userData} />} />
									<Route exact path="/:compid" render={() => <Competition userData={this.state.userData} />} />
									<Route exact path="/:compid/leaderboard" render={() => <Leaderboard userData={this.state.userData} />} />
									<Route exact path="/:compid/prizes" render={() => <Prizes userData={this.state.userData} />} />
									<Route exact path="/:compid/terms-and-conditions" render={() => <TermsConditions userData={this.state.userData} />} />
									<Route component={NoAccess} />
								</Switch>
							</div>
	
						</React.Fragment>
					</Router>
				</div>
			);
		} else {
			console.log('user is not authenticated!');
			return (
				<div className="betfair-tipping">
					<Router>
						<React.Fragment>
	
							<div className="bf-content">
								<Switch>
									<Route component={Login} />
								</Switch>
							</div>
	
						</React.Fragment>
					</Router>
				</div>
			);
		}
	}
}

export default App;
