import axios from 'axios';
import authController from './AuthController';

function requestHeader() {
    const headers = {
        headers: {
            "X-Authentication": authController.getAuthToken(),
            "Content-Type": "application/json"
        }
    };

    return headers;
}

async function getUserDetails(compId) {
    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/userDetails/${compId}`, requestHeader());
    console.log('(getUserDetails) resp :: ', resp);
    return await resp;
}

async function getCompList() {
    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/getCompList`, requestHeader());
    console.log('(getCompList) resp :: ', resp);
    return await resp;
}

async function getCompDetails(compId) {
    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/getCompDetails/${compId}`, requestHeader());
    console.log('(getCompDetails) resp :: ', resp);
    return await resp;
}

async function getLeaderBoard(compId) {
    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/getLeaderBoard/${compId}`, requestHeader());
    console.log('(getLeaderBoard) resp :: ', resp);
    return await resp;
}

function updateTipps(values, successCB, errorCB) {
    axios.post(`${process.env.REACT_APP_API_URL}/updateTipps/`, values, requestHeader())
        .then((response) => {
            successCB(response.data)
        })
        .catch((error) => {
            errorCB(error)
        }) 
}

function updateTieBreak(values, successCB, errorCB) {
    axios.post(`${process.env.REACT_APP_API_URL}/updateTieBreak/`, values, requestHeader())
        .then((response) => {
            successCB(response.data)
        })
        .catch((error) => {
            errorCB(error)
        }) 
}


export default {
    getUserDetails,
    getCompList,
    getLeaderBoard,
    getCompDetails,
    updateTipps,
    updateTieBreak
}