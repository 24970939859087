import React from 'react';
import { withRouter } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import PropagateLoader from 'react-spinners/PropagateLoader';

import './Login.scss';

// Components

import Footer from '../Footer/Footer';


class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataReady: false
        }
    }

    componentDidMount() {
        this.setState({dataReady : true});
    }

    render() {
        if (this.state.dataReady) {

            return (
                <LoadingOverlay classNamePrefix='bf-page-loader-' active={!this.state.dataReady} fadeSpeed={Number(process.env.REACT_APP_LOADER_FADE_SPEED)} spinner={<PropagateLoader sizeUnit={"px"} size={24} />}>
                    <React.Fragment>
                        <div className="login-container">

                            <div className="top-header-bar">
                                <div className="container">
                                    <div className="row">

                                        <div className="col">
                                            <div className="logo-wrapper">
                                                <a href={process.env.REACT_APP_PUBLIC_URL} className="betfair-logo float-left" title="Betfair | Tipping">&nbsp;</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="bs-container container">
                                <div className="row">
                                    <div className="col">

                                        <div className="login-wrapper">
                                            <form action={process.env.REACT_APP_LOGIN_API_URL} method="post" id="loginForm">
                                                <div className="login-header">
                                                    <h2>login</h2>
                                                    <div className="icon-login" />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="username">Username</label>
                                                    <input type="text" name="username" id="username" autoComplete="off" />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="password">Password</label>
                                                    <input type="password" name="password" id="password" autoComplete="off" />
                                                </div>

                                                <input type="hidden" name="redirectMethod" value="GET" />
                                                <input type="hidden" name="product" value="exchange" />
                                                <input type="hidden" name="url" value={`${process.env.REACT_APP_PUBLIC_URL}${this.props.location.pathname}`} />

                                                <div className="login-footer">
                                                    <button name="login" id="login" type="submit" value="true" className="button-login">LOG IN</button>
                                                    <a href={process.env.REACT_APP_RECOVER_USERNAME_URL} className="forgot-account float-right">Forgotten Account?</a>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                      
                            <div className="login-footer-wrapper">
                                <Footer />
                            </div>

                        </div>
                    </React.Fragment>
                </LoadingOverlay>
            );

          } else {
            return (
                <LoadingOverlay classNamePrefix='bf-page-loader-' active={!this.state.dataReady} fadeSpeed={Number(process.env.REACT_APP_LOADER_FADE_SPEED)} spinner={<PropagateLoader sizeUnit={"px"} size={24} />}></LoadingOverlay>
            );
          }
    }
}

export default withRouter(Login)
