


function validateForm(formData) {
    const elmList = document.getElementsByClassName("validate-field");
    let h = 0;
    let hasError = false;

    if (elmList === null) {
        return hasError;
    }

    for (h === 0; h < elmList.length; h++) { // Get list of HTML elements with 'validate-field' css class.
        if (elmList[h].getElementsByTagName("input")[0].name) { // Get element within that contains the input tag and get its name
            if (formData[elmList[h].getElementsByTagName("input")[0].name]) { // Compare to see if the name exists within our dynamically created tipsData object
                if (elmList[h].classList.contains('field-error')) {
                    // remove field-error css class from element with validate-field class
                    elmList[h].classList.remove('field-error');
                }
            } else {
                // add field-error css class to element with validate-field class
                elmList[h].classList.add('field-error');
                hasError = true;
            }
        }
    }

    return hasError;
}

function resetFormValidation() {
    const elmList = document.getElementsByClassName("validate-field");
    const formButtons = document.getElementsByClassName("btn-radio");
    let h = 0;
    let i = 0;
    let hasError = false;

    if (elmList === null || formButtons === null) {
        return hasError;
    }

    for (i === 0; i < formButtons.length; i++) { // Get list of HTML elements with 'btn-radio' css class.
        if (formButtons[i].classList.contains('active')) {
            // remove active css class from element with btn-radio class
            formButtons[i].classList.remove('active');
        }
    }

    for (h === 0; h < elmList.length; h++) { // Get list of HTML elements with 'validate-field' css class.
        if (elmList[h].getElementsByTagName("input")[0].name) { // Get element within that contains the input tag and get its name
            if (elmList[h].classList.contains('field-error')) {
                // remove field-error css class from element with validate-field class
                elmList[h].classList.remove('field-error');
            }
        }
    }

    return hasError;
}

function validateIndividualField(fieldName) {
    const elmList = document.getElementsByClassName("validate-field");
    let h = 0;

    if (elmList === null) {
        return;
    }

    for (h === 0; h < elmList.length; h++) { // Get list of HTML elements with 'validate-field' css class.
        if (elmList[h].getElementsByTagName("input")[0].name === fieldName) { // Get element within that contains the input tag and get its name
            if (elmList[h].classList.contains('field-error')) {
                // remove field-error css class from element with validate-field class
                elmList[h].classList.remove('field-error');
            }
        }
    }
}


export default {
    validateForm,
    validateIndividualField,
    resetFormValidation
}