import React from 'react';

import './UserProfile.scss';


export default class UserProfile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="user-profile-container float-left">
                <div className="user-circle" title={`${this.props.userData.firstName} ${this.props.userData.lastName}`}>
                    <span>{this.props.userData.firstName.charAt(0)}{this.props.userData.lastName.charAt(0)}</span>
                </div>

                <div className="user-details">
                    <div className="user-name">Welcome {this.props.userData.firstName}</div>
                    <div className="punter-detail">Punter Number: <span title={this.props.userData.compUserID}>{this.props.userData.compUserID ? this.props.userData.compUserID : '-'}</span></div>
                </div>
            </div>
        )
    }
}