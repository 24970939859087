import React from 'react';
import { withRouter } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import PropagateLoader from 'react-spinners/PropagateLoader';
import moment from 'moment';

import './Competition.scss';

// Controllers

import apiController from '../../controllers/ApiController';
import localStorageController from '../../controllers/LocalStorageController';

// Components

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import NoAccess from '../NoAccess/NoAccess';
import CompetitionHeader from './CompetitionHeader/CompetitionHeader';
import CompetitionNav from './CompetitionNav/CompetitionNav';
import CompetitionBody from './CompetitionBody/CompetitionBody';


class Competition extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            compId: null,
            userData: {},
            compData: {},
            currentRoundArrayIdx: 0,
            dataReady: false
        }

        this.dataRefreshHandler = this.dataRefreshHandler.bind(this);
        this.changeRoundHandler = this.changeRoundHandler.bind(this);
    }

    componentDidMount() {
        this.checkLocalStorageForData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ dataReady: false }); // Resets our dataReady in case this component is already loaded at a diff pathname.
            this.checkLocalStorageForData();
        }
    }

    checkLocalStorageForData() {
        if (localStorageController.isAvailable()) {
            const storedUserData = localStorageController.getData('bft.userData');
            const storedCompData = localStorageController.getData('bft.compData');
            if (storedUserData && storedUserData.dataValidUntil && storedCompData && storedCompData.dataValidUntil) {
                const userDataTimeDiff = moment().diff(storedUserData.dataValidUntil, 'minutes');
                const compDataTimeDiff = moment().diff(storedCompData.dataValidUntil, 'minutes');
                if (userDataTimeDiff > 0 || compDataTimeDiff > 0) {
                    this.getCompetitionAndUserData();
                } else {
                    if (Number(this.props.match.params.compid) === Number(storedCompData.savedData.competitionID)) { // check if local storage comp id is same as browser URL compid, if so use localstorage data, otherwise force get fresh data
                        setTimeout(() => { // timeout here to act as a small "nice transition"
                            this.setState({ compId: this.props.match.params.compid, userData: storedUserData.savedData, compData: storedCompData.savedData });
                            this.findAndSetCurrentRound(storedCompData.savedData);
                        }, 500);
                    } else {
                        this.getCompetitionAndUserData();
                    }
                }
            } else {
                this.getCompetitionAndUserData();
            }
        } else {
            this.getCompetitionAndUserData();
        }
    }

    async getCompetitionAndUserData() {
        const compId = this.props.match.params.compid;
        this.setState({compId: compId});

		const res1 = await apiController.getUserDetails(compId);
		if (res1.status === 200) {
            localStorageController.storeData('bft.userData', res1.data);
            this.setState({userData: res1.data});
            
            const res2 = await apiController.getCompDetails(compId);
            if (res2.status === 200) {
                localStorageController.storeData('bft.compData', res2.data);
                this.setState({compData: res2.data});
                this.findAndSetCurrentRound(res2.data);
            }
		}
    }

    findAndSetCurrentRound(cp) { // loop through comp rounds and find the round that's open or opening soon and set this as our default.
        if (cp.rounds !== undefined) {
            const now = Math.floor(Date.now()/1000); // now
            let i = 0;
            let h = 0;
            let currentRound = null;
            console.log('attempting to find current active round..');
            for (i === 0; i < cp.rounds.length; i++) {
                if (cp.rounds[i].start_time < now && cp.rounds[i].end_time > now) {
                    currentRound = i;
                    console.log('found a current open round!!', cp.rounds[i].roundID, 'index is:', i);
                    break; // break and stop the loop as soon as we find the first current round.
                }
            }
    
            if (currentRound === null) { // if currentRound is still null.. In case we never found a round that is currently open, let's try and find the next round opening soon
                console.log('currentRound is still null, attempting to find opening soon round..');
                for (h === 0; h < cp.rounds.length; h++) {
                    if (cp.rounds[h].start_time > now && cp.rounds[h].end_time > now) {
                        currentRound = h;
                        console.log('found a round that is opening soon!!');
                        break; // break and stop the loop as soon as we find the first current opening soon round
                    }
                }
            }
    
            if (currentRound === null) { // if currentRound is STILL null... lets just default our round array index to the first round being 0 (zero)
                console.log('welp.. we are s!@# outta luck.. defaulting round index to 0');
                currentRound = 0;
            }
            
            this.setState({currentRoundArrayIdx: currentRound, dataReady: true});
        } else {
            this.setState({dataReady: true}); // No competition existed so continue
        }
    }

    dataRefreshHandler() {
        this.getCompetitionAndUserData();
    }

    changeRoundHandler(evt) {
        this.setState({ currentRoundArrayIdx: evt.currentTarget.getAttribute("data-round") });
    }

    render() {
        if (this.state.dataReady && (this.state.userData && this.state.compData.competitionID) && this.state.userData.isAllowedInComp) { // If we are allowed in comp

            return (
                <LoadingOverlay classNamePrefix='bf-page-loader-' active={!this.state.dataReady} fadeSpeed={Number(process.env.REACT_APP_LOADER_FADE_SPEED)} spinner={<PropagateLoader sizeUnit={"px"} size={24} />}>
                    <React.Fragment>
                        <Header userData={this.state.userData} />
                        
                        <div className="content-area">
                            <div className="container">
                                <div className="row">
                                    <div className="col">

                                        <div className="competition-container">

                                            <CompetitionHeader compData={this.state.compData} roundIdx={this.state.currentRoundArrayIdx} refreshData={this.dataRefreshHandler} />
                                            <CompetitionNav compData={this.state.compData} roundIdx={this.state.currentRoundArrayIdx} changeRound={this.changeRoundHandler} />
                                            <CompetitionBody compId={this.state.compId} compData={this.state.compData} roundIdx={this.state.currentRoundArrayIdx} refreshData={this.dataRefreshHandler} />

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </React.Fragment>
                </LoadingOverlay>
            );

          } else if (this.state.dataReady && (this.state.userData && this.state.compData) && !this.state.userData.isAllowedInComp) { // If not allowed in comp
            return (
                <LoadingOverlay classNamePrefix='bf-page-loader-' active={!this.state.dataReady} fadeSpeed={Number(process.env.REACT_APP_LOADER_FADE_SPEED)} spinner={<PropagateLoader sizeUnit={"px"} size={24} />}>
                    <NoAccess />
                </LoadingOverlay>
            );
          } else {
            return (
                <LoadingOverlay classNamePrefix='bf-page-loader-' active={!this.state.dataReady} fadeSpeed={Number(process.env.REACT_APP_LOADER_FADE_SPEED)} spinner={<PropagateLoader sizeUnit={"px"} size={24} />}></LoadingOverlay>
            );
          }
    }
}

export default withRouter(Competition)
