import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'core-js/fn/promise'; // Promise() fallback to support IE11
import 'core-js/fn/object/assign'; // OBject.Assign() fallback to support IE11
import 'core-js/fn/string/repeat'; // .repeat fallback to support IE11
import 'core-js/fn/array/find-index'; // .findIndex fallback to support IE11
import 'core-js/fn/array/includes'; // .includes fallback to support IE11
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie9';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
