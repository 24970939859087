import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Sticky from 'react-sticky-el';
import { Scrollbars } from 'react-custom-scrollbars';

import './LeaderboardBody.scss';


class LeaderboardBody extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            roundIdx: 0,
            displayType: this.props.displayType,
            userData: this.props.userData,
            compData: this.props.compData,
            leaderboardData: this.props.leaderboardData,
            tableData: [],
            tableDataForUser: [],
            tableDataForHeader: [],
            dataReady: false
        }
    }

    componentDidMount() {
        this.setState({
            roundIdx: this.props.roundIdx
        });
        // this.isOverflown();
        this.generateTableData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.roundIdx !== prevProps.roundIdx || this.props.displayType !== prevProps.displayType) {
            this.setState({
                displayType: this.props.displayType,
                compData: this.props.compData,
                leaderboardData: this.props.leaderboardData,
                roundIdx: this.props.roundIdx
            });
            const { scrollbar } = this.refs; // scroll bar api plugin
            if (scrollbar !== undefined) {
                scrollbar.scrollToTop(); // Scroll back to top of inner container
            }
            this.generateTableData();
        }
    }

    /*isOverflown() {
        setTimeout(() => {
            const scrollContainer = document.getElementById('scrollingContentContainer');
            const scrollContent = document.getElementById('scrollingContent');
            const scrollIndicator = document.getElementById('scrollingIndicator');
            if (scrollContainer && scrollContent && scrollIndicator) {
                if (scrollContent.clientHeight > scrollContainer.clientHeight) {
                    scrollIndicator.classList.add('show-indicator');
                }
            }
        }, 700);
    }*/

    generateTableData() {
        let i = 0;
        let dataForTable = [];
        let specificUserDataRow = [];
        let dataTableHeaderData = [];

        //console.log('generating table data... this.props.displayType', this.props.displayType);
        
        if (this.props.displayType === 'overall') {
            //console.log('generating overall data');
            if (this.state.leaderboardData.ladder !== undefined && this.state.leaderboardData.ladder.length > 0) {
                const lbData = this.state.leaderboardData.ladder;
                
                for (i === 0; i < lbData.length; i++) {
                    let pos = i;
                    dataForTable.push(
                        {form: null, position: (pos+1), punter: lbData[i].userID, margin: lbData[i].tie_score.toFixed(2), score: lbData[i].tip_score}
                    );

                    if (lbData[i].userID === this.props.userData.compUserID) {
                        //console.log('overall -- punter', lbData[i].userID, 'compUserID', this.props.userData.compUserID);
                        specificUserDataRow.push({form: null, position: (pos+1), punter: lbData[i].userID, margin: lbData[i].tie_score.toFixed(2), score: lbData[i].tip_score})
                    }
                }
            }
        } else if (this.props.displayType === 'rounds') {
            //console.log('generating round data');
            if (this.state.leaderboardData.roundLadders !== undefined && this.state.leaderboardData.roundLadders.length > 0) {
                if (this.state.leaderboardData.roundLadders[this.props.roundIdx].ladder && this.state.leaderboardData.roundLadders[this.props.roundIdx].ladder.length > 0) {
                    const lbData = this.state.leaderboardData.roundLadders[this.props.roundIdx].ladder;
                
                    for (i === 0; i < lbData.length; i++) {
                        let pos = i;
                        dataForTable.push(
                            {form: (pos+1), position: (pos+1), punter: lbData[i].userID, margin: lbData[i].tie_score.toFixed(2), score: lbData[i].tip_score}
                        );

                        if (lbData[i].userID === this.props.userData.compUserID) {
                            //console.log('round -- punter', lbData[i].userID, 'compUserID', this.props.userData.compUserID);
                            specificUserDataRow.push({form: null, position: (pos+1), punter: lbData[i].userID, margin: lbData[i].tie_score.toFixed(2), score: lbData[i].tip_score})
                        }
                    }
                }
            }
        } else {
            dataForTable = [];
            specificUserDataRow = [];
            dataTableHeaderData = [];
        }

        this.setState({ tableDataForHeader: dataTableHeaderData, tableDataForUser: specificUserDataRow, tableData: dataForTable, dataReady: true });
    }

    formatFormCell = (cell, row) => {
        // console.log('cell', cell, 'row', row, 'this.props.roundIdx:', this.props.roundIdx);
        if (this.props.roundIdx > 0) { // we are round 2 or more, so compare and get the difference
            if (row.punter === this.state.userData.compUserID) {
                let lastRoundIdx = (this.props.roundIdx-1);
                let newPos = row.position;
                let oldPos = this.state.leaderboardData.roundLadders[lastRoundIdx].userPosition;
                // console.log('New Pos', newPos, 'Old Pos', oldPos, 'difference', Math.abs(newPos - oldPos), 'reversed', Math.abs(oldPos - newPos));
                // console.log('round position:', row.position, 'last round position', this.state.leaderboardData.roundLadders[lastRoundIdx].userPosition);
                if (newPos >= oldPos) {
                    return (
                        <span className="rank-down">{Math.abs(newPos - oldPos)}</span>
                    );
                }
                
                if (oldPos > newPos) {
                    return (
                        <span className="rank-up">{Math.abs(newPos - oldPos)}</span>
                    );
                }
            }
            
            return (
                <span>-</span>
            );
        } else { // We are the first round..
            return (
                <span className="rank-up">{row.position}</span>
            );
        }
    };

    userRowHighlight = (row, rowIndex) => {
        return (row && row.punter && row.punter === this.state.userData.compUserID) ? 'highlight-user-row' : null;
    };
    
    userFixedRowHighlight = (row, rowIndex) => {
        return (row && row.punter && row.punter === this.state.userData.compUserID) ? 'highlight-user-row-fixed' : null;
    };


    render() {
        if (this.state.tableData && this.state.tableData.length > 0 && this.state.dataReady) {
            
            const dataTableData = this.state.tableData;
            const dataTableUserData = this.state.tableDataForUser;
            const dataTableHeaderData = this.state.tableDataForHeader;
            
            const columns = [{
                dataField: 'form',
                text: 'Form',
                hidden: (this.props.displayType === 'overall') ? true : false,
                formatter: this.formatFormCell,
                headerStyle: (colum, colIndex) => {
                    return { width: '40px', maxWidth: '40px', textAlign: 'left' };
                },
                style: (cell, row, rowIndex, colIndex) => {
                    return { width: '40px', maxWidth: '40px', textAlign: 'left' }
                },
                classes: (cell, row, rowIndex, colIndex) => {
                    return 'form-column';
                }
            }, {
                dataField: 'position',
                text: 'Position',
                headerStyle: (colum, colIndex) => {
                    return { width: '55px', maxWidth: '55px', textAlign: 'left' };
                },
                style: (cell, row, rowIndex, colIndex) => {
                    return { width: '55px', maxWidth: '55px', textAlign: 'left' }
                },
                classes: (cell, row, rowIndex, colIndex) => {
                    return 'position-column';
                }
            }, {
                dataField: 'punter',
                text: 'Punter',
                headerStyle: (colum, colIndex) => {
                    return { maxWidth: '180px', width: '180px', textAlign: 'left' };
                },
                style: (cell, row, rowIndex, colIndex) => {
                    return { maxWidth: '180px', width: '180px', textAlign: 'left' }
                },
                headerClasses: (cell, row, rowIndex, colIndex) => {
                    return 'punter-column-header';
                },
                classes: (cell, row, rowIndex, colIndex) => {
                    return 'punter-column';
                }
            }, {
                dataField: 'margin',
                text: 'Margin',
                headerAlign: 'right',
                align: 'right',
                headerStyle: (colum, colIndex) => {
                    return { width: '50px' };
                },
                style: (cell, row, rowIndex, colIndex) => {
                    return { width: '50px' }
                },
                classes: (cell, row, rowIndex, colIndex) => {
                    return 'margin-column';
                }
            }, {
                dataField: 'score',
                text: 'Score',
                headerAlign: 'right',
                align: 'right',
                headerStyle: (colum, colIndex) => {
                    return { width: '50px' };
                },
                style: (cell, row, rowIndex, colIndex) => {
                    return { width: '50px' }
                },
                classes: (cell, row, rowIndex, colIndex) => {
                    return 'score-column';
                }
            }];

            return (
                <div className="lead-body-footer-wrapper">

                    <div className="lead-body content-responsive">
                        <BootstrapTable
                            wrapperClasses='bf-table leaderboard-header-row'
                            keyField='position'
                            data={dataTableHeaderData}
                            columns={columns}
                            bordered={false}
                        />

                        <Scrollbars style={{ width: '100%', height: 333 }} ref="scrollbar">
                            <BootstrapTable
                                wrapperClasses='bf-table leaderboard-data'
                                keyField='position'
                                data={dataTableData}
                                columns={columns}
                                bordered={false}
                                rowClasses={this.userRowHighlight}
                            />
                        </Scrollbars>
                        
                        <div className="fixed-user-container">
                            <Sticky mode="bottom" boundaryElement=".leaderboard-container" style={{ position: 'relative' }} hideOnBoundaryHit={false}>
                                <BootstrapTable
                                    wrapperClasses='bf-table user-data-row'
                                    keyField='position'
                                    data={dataTableUserData}
                                    columns={columns}
                                    bordered={false}
                                    rowClasses={this.userFixedRowHighlight}
                                />
                            </Sticky>
                        </div>

                    </div>

                    <div className="lead-footer content-responsive">
                        <div className="container">
                            <div id="scrollingIndicator" className="scroll-down-indicator"><span></span></div>
                            <div className="row">
                                <div className="col">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
          } else {
            return (
                <div className="lead-body-footer-wrapper">

                    <div className="lead-body content-responsive">
                        <div className="no-data-message">Leaderboard will be updated when the round is completed</div>
                    </div>

                    <div className="lead-footer content-responsive">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
          }
    }
}

export default withRouter(LeaderboardBody)
