import React from 'react';
import { withRouter } from 'react-router-dom';

import './LeaderboardNav.scss';


class LeaderboardNav extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            displayType: this.props.displayType,
            prevIdx: null,
            nextIdx: null,
            roundIdx: 0,
            compData: this.props.compData,
            dataReady: false
        }
    }

    componentDidMount() {
        console.log('leaderboard nav props', this.props.compData);
        this.setState({roundIdx: this.props.roundIdx});
        this.updateLeaderboardRoundNav();
    }

    componentDidUpdate(prevProps) {
        if (this.props.displayType !== prevProps.displayType) {
            this.setState({displayType: this.props.displayType});
        }
        
        if (this.props.roundIdx !== prevProps.roundIdx) {
            this.setState({compData: this.props.compData, roundIdx: this.props.roundIdx});
            this.updateLeaderboardRoundNav();
        }
    }

    updateLeaderboardRoundNav() {
        let prevIdx = Number(this.props.roundIdx);
        prevIdx--;
        let nextIdx = Number(this.props.roundIdx);
        nextIdx++;
        this.setState({prevIdx: prevIdx--, nextIdx: nextIdx++, dataReady: true});
    }

    render() {
        if (this.state.displayType === "rounds" && this.state.compData && (this.state.compData.leaderboard.roundLadders !== undefined && this.state.compData.leaderboard.roundLadders.length > 0) && this.state.dataReady) {

            return (
                <div className="lead-navbar content-responsive">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                               <i data-round={this.state.prevIdx} onClick={this.props.changeRound} className={Number(this.state.roundIdx) <= 0 ? 'hiding' : 'icon-chevron-left previous-round'} title="Previous Round"></i>
                               {this.state.compData.rounds[this.state.roundIdx].description}
                               <i data-round={this.state.nextIdx} onClick={this.props.changeRound} className={Number(this.state.roundIdx) === this.state.compData.leaderboard.roundLadders.length-1 ? 'hiding' : 'icon-chevron-right next-round'} data-toggle="tooltip" title="Next Round"></i>
                            </div>
                        </div>
                    </div>
                </div>
            );
          } else {
            return (
                <div></div>
            );
          }
    }
}

export default withRouter(LeaderboardNav)
